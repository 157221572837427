import { useEffect, useMemo } from "react";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { datadogRum } from "@datadog/browser-rum";
import { PropTypes } from "prop-types";

const initializeDatadogRum = (userId, email) => {
  datadogRum.init({
    applicationId: window.feConfig.DATADOG_APPLICATION_ID,
    clientToken: window.feConfig.DATADOG_CLIENT_TOKEN,
    site: "ddog-gov.com",
    service: "mission-control",
    env: window.feConfig.ENVIRONMENT,
    version: window.feConfig.FE_CONFIG_IMAGE_TAG || "local",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    proxy: (options) =>
      `${window.feConfig.DATADOG_PROXY_URL}/${options.path}?${options.parameters}`,
    allowedTracingUrls: [
      /https:\/\/core\.uniteus.*\.com\/.*/,
      /https:\/\/core\.uniteus\.io\/.*/,
      /https:\/\/datadog\.uniteus\.com\/.*/,
      /https:\/\/datadog\.uniteus\.io\/.*/,
    ],
  });
  datadogRum.startSessionReplayRecording();
  datadogRum.setUser({
    id: userId,
    email: email,
  });
};

const DatadogWrapper = ({ children }) => {
  const { authToken } = useAuthContext();
  const { email, userId } = useMemo(() => {
    const parsedAuthToken = JSON.parse(atob(authToken.split(".")[1]));
    return { email: parsedAuthToken?.email, userId: parsedAuthToken?.sub };
  }, [authToken]);

  useEffect(() => {
    if (email && userId) {
      initializeDatadogRum(userId, email);
    }
  }, [email, userId]);

  return <>{children}</>;
};

DatadogWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DatadogWrapper;
